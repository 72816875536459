@media only screen and (max-width: 1207px) {
  .start-page .start-mid { max-width: 704px; }
}

@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 991px) {
  .cs-tabs.brand-messaging-submissions .brand-messaging-title { font-size: 24px; }
  .cs-tabs.brand-messaging-submissions .response-wrapper { padding: 0px 0px; }
  .cs-tabs.brand-messaging-submissions .mat-tab-label-content { font-size: 16px; }
  .cs-tabs.brand-messaging-submissions .response-wrapper .response-member { font-size: 16px; }

}
@media only screen and (max-width: 899px) {
  .header .epic-pay-logo { display: none; }
  .toolbar-wrapper { flex: 0 0 100%; }
  .mainmenu { position: fixed; z-index: 99; transition-duration: 0.4s; }
  .mainmenu-mobile-open { left: 0px; }
  .mainmenu-mobile-closed { left: calc(-100% - 60px); }
  .mobile-open-overlay { position: fixed; top: 0px; bottom: 0px; left: 0px; right: 0px; background-color: #000; z-index: 98; opacity: 0.3; }
}

@media only screen and (max-width: 855px) {
  .start-page .start-mid { max-width: 350px; }
}
@media only screen and (max-width: 767px) {
  .cs-tabs .video-content br { display:none; }
  .cs-tabs.bold-version .question-heading br { display:none; }
  .cs-tabs span.mat-button-wrapper { white-space: normal; }
  .cs-tabs .question-content .dropdown .add-another .csbutton { line-height: normal; }
  .cs-tabs.keywords-submissions .question-block { padding: 20px; }
}

@media only screen and (max-width: 600px) {
  .header .header-text span { font-size: 20px; }
  .header .header-text { position: absolute; top: 90px; width: 100%; left: 0px; }
  .header .username .dropdown { min-width: 150px; }
  .cs-tabs .mat-tab-group { margin-top: 58px; }
  .mainmenu { width:calc(100% - 60px); }
  .cs-tabs .mat-tab-label-content { font-size: 14px; }
  .cs-tabs .video-content { font-size: 14px; }
  .cs-tabs .question-content .voice-and-tone .main-title, .cs-tabs .question-content .brand-personality .main-title { font-size: 14px; }
  .cs-tabs .question-content .top-input-field { font-size: 14px; }
  .cs-tabs .question-content .brand-personality .mat-accordion .mat-expansion-panel-header.mat-expanded .mat-expansion-panel-header-title { font-size: 14px; }
  .cs-tabs .question-content .question-block .question-block-title { font-size: 14px; }
  .cs-tabs .question-content .question-block .mat-input-element { font-size: 14px; }
  .csbutton { font-size: 15px; }
  .cs-tabs .question-content .question-tabs .question-tab-title { margin-top: 15px; }
  .cs-tabs .question-content .question-group .question-block-title { flex: 0 0 100%; font-size: 14px; }
  .cs-tabs .question-content .question-group .mat-form-field { flex: 0 0 100%; }
  .cs-tabs .question-content .question-group .question-block { margin-bottom: 15px; }
  .cs-tabs .question-content .question-group { margin-bottom: 30px; }
  .cs-tabs .example-content th, .cs-tabs .example-content td { font-size: 12px; }
  .cs-tabs .question-content { padding: 20px 30px; }
  .cs-tabs .question-content .dropdown .dropdown-title { font-size: 14px; }
  .cs-tabs .question-content .dropdown { margin-top: 15px; }
  .cs-tabs .question-content .dropdown .dropdown-wrapper .dropdown-wrapper-left { font-size: 12px; }
  .cs-tabs .question-content .dropdown .dropdown-wrapper mat-select .mat-select-placeholder { font-size: 14px; }
  .cs-tabs .question-content .dropdown .add-another .csbutton { font-size: 12px; width: 100%; }
  .cs-tabs.brand-p-tabs .question-content .question-block .mat-form-field-appearance-outline .mat-input-element::placeholder,
  .cs-tabs.voice-and-tone .question-content .question-block .mat-form-field-appearance-outline .mat-input-element::placeholder { white-space: normal; }
  // .cs-tabs.brand-p-tabs .question-content .question-block .mat-form-field-appearance-outline .mat-input-element,
  // .cs-tabs.voice-and-tone .question-content .question-block .mat-form-field-appearance-outline .mat-input-element { min-height: 60px; transform: translateY(-6px); margin: 0; }
  // .cs-tabs.brand-p-tabs .question-content .question-block .mat-form-field-appearance-outline .mat-form-field-infix,
  // .cs-tabs.voice-and-tone .question-content .question-block .mat-form-field-appearance-outline .mat-form-field-infix { padding: 0px 0 0px 0; border-top-width: 0; }
  .yellowbtn { min-width: 100% !important; }
  .cs-tabs.bold-version .video-content { font-size: 14px; }
  .cs-tabs.bold-version .question-heading { font-size: 14px; }
  .cs-tabs.bold-version .question-content .question-block .question-block-title { font-size: 16px; }
  .cs-tabs.bold-version .mat-tab-label-content { font-size: 18px; }
  .cs-tabs .question-content .mat-stepper-horizontal .mat-horizontal-stepper-header-container { padding: 0px 0px; }
  .cs-tabs .question-content .mat-stepper-horizontal .mat-step-text-label { font-size: 13px; }
  .cs-tabs .question-content .dropdown .actionBtn .next-button,
  .cs-tabs .question-content .brand-voice-chart-tabs .actionBtn .next-button,
  .cs-tabs .question-content .question-tabs .actionBtn .next-button,
  .cs-tabs .question-content .golden-pyramid-wrapper .actionBtn .next-button,
  .cs-tabs .question-content .problem-priority-wrapper .actionBtn .next-button,
  .cs-tabs .question-content .brand-messaging-wrapper .actionBtn .next-button,
  .cs-tabs.personification-page .actionBtn .next-button
  { min-width: 100%; width: 100% !important; line-height: normal; }
  .frame { width: calc(100% - 60px); }
  .cs-tabs.new-submissions-design .submissions-title { margin-top: 40px; }
  .cs-tabs.brand-messaging-submissions .brand-messaging-title { font-size: 22px; line-height: normal; }
  .cs-tabs.segmentations-submissions .category-wrapper .category-title { flex: 0 0 calc(100% - 55px); margin-right: 0px; }
  .cs-tabs.segmentations-submissions .category-wrapper .segment-wrapper { flex: 0 0 100%; margin-bottom: -5px; margin-top: 10px; }
  .cs-tabs.segmentations-submissions .segmentation-table-title { font-size: 26px; }
  .cs-tabs.segmentations-submissions .category-wrapper .category-title { font-size: 18px; }
  .cs-tabs.segmentations-submissions .category-wrapper .initials { font-size: 14px; }
  .cs-tabs.segmentations-submissions .category-wrapper .segment { font-size: 15px; }
  .cs-tabs.voice-and-tab-submissions .response-table table,
  .cs-tabs.brand-voice-chart-submissions .response-table table
   { min-width: 600px; }
  .cs-tabs.voice-and-tab-submissions .question-content,
  .cs-tabs.brand-voice-chart-submissions .question-content { overflow-x: scroll; }
  .cs-tabs.voice-and-tab-submissions .question-content,
  .cs-tabs.brand-voice-chart-submissions .question-content { padding: 15px 15px; }
  .cs-tabs.voice-and-tab-submissions .response-table,
  .cs-tabs.brand-voice-chart-submissions .response-table { padding: 15px 0px; }
  .cs-tabs.new-submissions-design .mat-tab-group { padding: 15px; }
  .cs-tabs.new-submissions-design .question-content .questions-wrapper { padding: 0px 10px 40px; }
  .cs-tabs.personification-submissions .question-results { padding: 0px 10px; }
  .cs-tabs.new-submissions-design .question-content .questions-wrapper { padding: 0px 10px 15px;  margin-top: 15px; }
  .cs-tabs.new-submissions-design .mat-tab-group .response-table th { font-size: 16px; }
  .cs-tabs.new-submissions-design.brand-advocacy-submissions .persona-details .persona-name { font-size: 16px; }
  .cs-tabs.keywords-submissions .question-content .mat-stepper-horizontal .mat-step-text-label { font-size: 12px; }
  .cs-tabs.new-submissions-design .question-content .question-block .question-block-title { font-size: 20px; }
}

@media only screen and (max-width: 480px) {
  .cs-tabs .question-content .dropdown .dropdown-wrapper .dropdown-wrapper-right { flex: 0 0 100%; }
  .start-page .start-mid .prework-block { flex: 0 0 100%; }
  .cs-tabs .question-content .mat-stepper-horizontal .mat-step-text-label { font-size: 11px; }
  .cs-tabs .question-content .mat-stepper-horizontal .mat-step-icon { height: 25px; width: 25px; font-size: 14px; }
  .cs-tabs .question-content .category-question-wrapper .question-block .mat-form-field { margin-left: 0px; width: 100%; }
  .cs-tabs .question-content .category-question-wrapper .question-block .prework-chip .mat-chip-list-wrapper { margin-left: 0px; }
  .cs-tabs.new-submissions-design .submissions-title { font-size: 26px; }
  .cs-tabs.brand-messaging-submissions .mat-tab-group { margin-top: 30px; }
  .cs-tabs.brand-messaging-submissions .response-wrapper .response-content { padding: 10px 0px 0px; }
  .cs-tabs.brand-messaging-submissions .response-wrapper { margin: 25px 0px; }
  .cs-tabs.brand-messaging-submissions .brand-messaging-title { font-size: 20px; }
  .cs-tabs.brand-messaging-submissions .mat-tab-label-content { font-size: 15px; }
  .cs-tabs.segmentations-submissions .segmentation-table-title { font-size: 22px; }
  .cs-tabs.personification-submissions .question-content .mat-stepper-horizontal .mat-step-icon { height: 30px; width: 30px; font-size: 18px; }
  .cs-tabs.personification-submissions .question-content .question-block .question-block-title { font-size: 16px; }
  .cs-tabs.personification-submissions .member { flex: 0 0 30px; height: 30px; width: 30px; font-size: 12px; }
  .cs-tabs.personification-submissions .response-result { flex: 0 0 calc(100% - 60px); }
  .cs-tabs.personification-page .tab-group-wrapper { padding: 30px 25px; }
  .cs-tabs.personification-page .question-content .mat-stepper-horizontal .mat-step-icon { height: 30px; width: 30px; font-size: 18px; }
}

@media only screen and (max-width: 375px) {
  .header .username { font-size: 14px; }
  .header .username .material-icons { font-size: 20px; }
  .cs-tabs.segmentations-submissions .category-wrapper .initials { font-size: 12px; height: 30px; width: 30px; margin-right: 10px; flex: 0 0 30px; }
  .cs-tabs.segmentations-submissions .category-wrapper .category-title { font-size: 16px; flex: 0 0 calc(100% - 40px); }
}

@media only screen and (max-width: 320px) {

}
