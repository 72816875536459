/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

$mainfont: "proxima-nova";
$bg-failure: #eb4d4b;
$bg-success: #6ab04c;
$bg-pending: rgba(189,100,0,0.6);

// Theme Colours
$themecolor1: #202945; // header
$themeaccentcolor1: #0c142e;
$themecolor2: #fbf9f2; // background
$themecolor3: #dfe4ea; // pagetitle
$themecolor4: #ffffff; // table-container
$themecolor5: #535c68; // menu

$menudividerlight: #743a87;
$menudividerdark: #572767;
$submenudividerlight: #8f51a4;
$submenudividerdark: #6b377c;

$buttoncolor1: #eb4d4b;
$buttonaccentcolor1: #ff7979;
$buttoncolor2: #f1f2f6;

$titlecolor1: #000000;
$titlecolor2: #227093;

// dark theme
// $themecolor1: #aaa69d;
// $themecolor2: #f7f1e3;



// Global styles
html {height: 100%;}
body {margin: 0px !important; font-family: $mainfont, sans-serif; overflow: hidden; }
h1 {font-size: 20px;font-weight: 700;font-family: $mainfont, sans-serif;color: $titlecolor1;}

// Body and Container
.mat-drawer-container {background: $themecolor2;background-size: cover;}
.container {height: 100vh;}

// Miscellaneous
.toolbarspacer {flex: 1 1 auto;}
.clear {clear: both;}

// Button Styles
.redbtn {background: $buttoncolor1;color: #fff;}
.redbtn:hover {color: #fff;background: $buttonaccentcolor1;}
.greybtn {background: $buttoncolor2;color: $titlecolor1;}
.greybtn:hover {color: $titlecolor1;}
.filterbtn {background: transparent !important;color: $titlecolor1;}

// Form Styles
.mat-form-field {font-family: $mainfont; }
// .mat-form-field .mat-form-field-outline-end { border: 1px solid #95a5a6 !important; }
// .mat-form-field-appearance-outline .mat-form-field-outline-start { border: 0px solid #95a5a6 !important; min-width: 0 !important; }
.mat-option { font-family: 'montserrat', sans-serif !important; }

// Remove browser unwanted styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $themecolor2 inset !important;
}


.vertical-layout, div.defaultLightbox, div.login, div.login > form  { display: flex; flex: 1; flex-direction: column; }

.horizontal-layout, div.main { display: flex; flex: 1; flex-direction: row; }


// Main Header
// ===========
.header {

    & { position: fixed; }

    .mat-toolbar-row {color: #fff;}
    .closebtn {display: block;background: $themeaccentcolor1;color: #fff;font-size: 24px;width: 20px;text-align: center;margin: 10px 60px 10px -16px;height: 30px;padding-top: 8px;transition: width 1s ease;}
    .menubtn {display: block;background: $themeaccentcolor1;color: #fff;font-size: 28px;width: 80px;text-align: center;margin: 0 20px 0 -16px;height: 48px;padding-top: 16px;transition: width 1s ease;}

    //.menu {display: block;color: #fff;font-size: 0.85em;text-transform: uppercase;font-family: $mainfont;padding: 16px 30px 9px;font-weight: 800;text-decoration: none;border-bottom: 5px solid $themecolor1;}
    .active {border-bottom: 7px solid #fff;}
    .logo img {width: 130px;margin-top: 10px;}

}

.mat-tab-group.mat-primary .mat-ink-bar .mat-tab-nav-bar.mat-primary .mat-ink-bar {background-color: $themecolor1 !important; }
.mat-ink-bar {height: 4px;}
.mat-tab-label-content {font-family: $mainfont;}
.mat-tab-labels {background: #fff;}



.aside {
    & {font-family: $mainfont;width: 90%;background: #ecf0f1;padding: 0;}

    .header { width: 100%; padding: 15px 2%; margin: 0; background: #FFF;box-shadow: rgba(0,0,0,0.2); text-transform: uppercase; }
    .content { margin: 20px; text-align: justify; }
    .actionBtn { display: flex; justify-content: flex-end; margin: 20px; }

}

.navbar {

    & {display: flex;flex-direction: column;width: 340px;background: #ced6e0; }

    a {
        & { display: block; width: 80%; margin: 0 auto; color: #FFF;
            text-decoration: none; font-size: 1.2em; padding: 5px 10px; border-bottom: 1px solid #3d8fb6;
            border-top: 1px solid #277499; font-family: $mainfont; }

        &:first-of-type { border-top: none; }

        &:last-of-type { border-bottom: none; }

        &:hover { color: #ecf0f1; }
    }
}

/* -- Menu Main -- */
/* -- ========= -- */
.mainmenu {

    & {width: 290px; background: $themecolor1;color: #fff; padding: 25px 30px 0px 30px; }

    .menulogo {height: 32px;margin: 6px 0 2px -20px;}

    .container {height: 100%;}
    nav h2 {color: #fff;font-size: 1.5em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 800;}
    nav h3 {color: #fff;font-size: 1.2em;letter-spacing: 0.1em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 600;}
    nav a {display: block;width: 90%;margin: 0 auto;color: #fff;text-decoration: none;font-size: 1.1em;font-weight: 300;padding: 15px 5px;background: none;border-bottom: 1px solid $menudividerlight;border-top: 1px solid $menudividerdark;}
    nav .profile {background: $themeaccentcolor1;padding: 20px;text-align: center;font-size: 1.4em;display: flex;align-items: center;}
    nav .profile .profilepic {flex: 0 0 60px;height: 47px;border-radius: 50%;background: $themecolor1;padding-top: 13px;}
    nav .profile .profiledetails {flex: 1 1 auto;font-size: 14px;text-align: left;line-height: 16px;margin-left: 10px;}
    nav .profile .profiledetails span {font-size: 12px;}
    nav .submenu {padding: 5px 5px 5px 20px;font-size: 0.9em;border-top: 1px solid $submenudividerlight;border-bottom: 1px solid $submenudividerdark;}
    nav .sub {background: $themeaccentcolor1;}
    nav a:first-of-type {border-top: none;}
    nav a:last-of-type {border-bottom: none;}
    nav a:hover {color: #ecf0f1;}
    nav a i {display: block;float: left;margin-top: -4px;margin-right: 20px;font-size: 28px;opacity: 0.3;}
    .logo { padding: 15px 10px; width: 90%; border: none; line-height: 0; }
    .logo img { width: 150px; }
}






// main h1 {margin: 10px 0 10px 0;font-size: 2.5em;font-weight: 800;text-transform: uppercase;color: #3c6382;}

// Main container and their styles
// -------------------------------
main {

    & {font-family: $mainfont;}

    // Main toolbar
    .pagetitle {background: transparent !important;width: 100%;}
    .mat-toolbar {background: transparent !important;}
    .mat-toolbar-row h1 {margin: 10px 0 10px 0;font-size: 1.5em;font-weight: 800;text-transform: uppercase;color: $titlecolor1;font-family: $mainfont;}
    .mat-toolbar-row button {font-weight: 800;font-family: $mainfont;text-transform: uppercase;margin-right: 5px;}

    .table-container {display: flex;flex-direction: column;min-width: 300px;background: $themecolor4;border-top: 2px solid $themecolor1;}

    // Mat Table
    // ---------
    .mat-table {font-family: $mainfont;overflow: auto;max-height: 80vh;}
    .mat-header-row {position: sticky;top: 0;background-color: #fff;z-index: 1000;}
    .mat-header-cell {font-size: 1em;color: $themecolor1;font-family: $mainfont;text-transform: uppercase;font-weight: 800;}
    .mat-menu-item {font-family: $mainfont;letter-spacing: 0;font-size: 12px;padding: 0 8px !important;height: auto !important;line-height: 36px !important;margin-bottom: 0;}

    .draft {background: #e55039;color: #fff;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}
    .running {background: #78e08f;color: #fff;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}
    .closed {background: none;color: #78e08f;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}

}

.mat-column-responses {flex: 0 0 160px !important;text-align: center !important;}
.mat-column-status {flex: 0 0 160px !important;text-align: center !important;}
.mat-column-tools {flex: 0 0 80px !important;text-align: right;}



app-lightbox { display: flex; justify-content: center; position: fixed;  width: 100%; height: 100%;
               background: rgba(0,0,0,0.5);  z-index: 10000; font-family: $mainfont; }

.lightbox {
    & { position: fixed; text-align: center; padding: 0px; background: #FFF;
        z-index: 10001; border-radius: 9px; min-height: 100px; top: 64px; font-family: $mainfont; }

    h1 { color: #FFF; background-color: #16577f; font-size: 1.5em; margin: 0px;
         padding: 20px 0px; border-top-right-radius: 7px; border-top-left-radius: 7px; }
}

.defaultLightbox > div {
    padding: 10px;
    font-family: $mainfont;
    &:last-of-type { display: flex;  justify-content: flex-end; }
    > p { text-align: justify; }
}

.login {

    & {min-width: 30vw;margin-top: 100px;font-family: $mainfont;background: $themecolor4;padding: 30px;}
    .loginheader {margin: -30px -30px 0 -30px;background: $themecolor1;padding: 20px;text-align: center;}
    .loginheader img {margin: 0 auto;width: 200px;}
    div.actionBtn { display: flex; justify-content: flex-end; }
    .mat-form-field {background: $themecolor4;}
    form {
        input[type="text"] { height: 16px; padding: 0px; border: 0; background: none; border-radius: 0px; }
        input[type="email"] { height: 16px; padding: 0px; border: 0; background: none; border-radius: 0px; }
        input[type="password"] { height: 16px; padding: 0px; border: 0; background: none; border-radius: 0px; }
        input[type="password"]:focus { border: none; outline: none; }
    }

}

// Snackbar container
// ----------------
.mat-snack-bar-container {margin: 20px !important;}
.mat-snack-bar-container-failure {background-color: $bg-failure;}
.mat-snack-bar-container-success {background-color: $bg-success;}
.mat-snack-bar-container-pending {background-color: $bg-pending;color: #fff;}

// ========================= CS CSS ==============================

// =================== Global CSS ===================

// fonts
@font-face {
  font-family: "butler-bold";
  src: url("/assets/fonts/Butler_Bold.ttf") format("truetype");
}

@font-face {
  font-family: "butler-extrabold";
  src: url("/assets/fonts/Butler_ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "butler-light";
  src: url("/assets/fonts/Butler_Light.ttf") format("truetype");
}

@font-face {
  font-family: "butler-medium";
  src: url("/assets/fonts/Butler_Medium.ttf") format("truetype");
}

@font-face {
  font-family: "butler";
  src: url("/assets/fonts/Butler_Regular.ttf") format("truetype");
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');
$cs_montserrat_fonts : "montserrat", sans-serif;

// theme colors
$csyellow: #F7D54E;

// buttons
.csbutton { text-transform: uppercase; font-family: $cs_montserrat_fonts; font-size: 16px; }
.bluebutton { background: $themecolor1; color: #fff !important; }
.yellowbtn { background: $csyellow; color: $themecolor1 !important; min-width: 300px !important; }
.greybutton { background: #e5e5e5; color: $themecolor1 !important; }

.inactive { background: #e5e5e5 !important; color: #535c68 !important; }

// classes
.main-frame { max-width: 677px; margin: 0px auto; width: 100%; }
.frame { max-width: 1440px; margin: 0px auto; width: calc(100% - 140px); }
.container.no-container { width: 100%; }
.container.container-collapsed { margin-left: 80px; }
//  loader

// other
.fa-lightbulb:before { content: "\f0eb"; }

// mat
.mat-form-field.mat-focused .mat-form-field-ripple { background-color: $themecolor1; }
.matspinner {display: flex;justify-content: center;align-items: center;min-height: 50vh;}

// =================== Global CSS ===================

// =================== Header ===================
.header {
  & { position: relative; border-bottom: 10px solid $csyellow; min-height: 80px; background-color: #fff; justify-content: space-between; }
  .menubtn { background: #fff; color: #9c9c9c; float: left; }
  .header-text { text-align: center; font-family: "butler-bold"; text-transform: capitalize; letter-spacing: 0.4px; font-size: 25px; }
  .header-text span { display: block; }
  .epic-pay-logo { line-height: 0; font-family: $cs_montserrat_fonts; font-size: 18px; font-weight: 600; color: $themecolor1; }
  .epic-pay-logo img { width: 150px; }
  .username {
    & { position: relative; display: flex; flex-wrap: wrap; align-items: center; font-family:$cs_montserrat_fonts; font-size:18px; font-weight: 600; color:$themecolor1; }
    .material-icons { margin-left: 5px; font-size: 28px; cursor: pointer; color:$themecolor1; transition: 0.4s; }
    .material-icons.rotate { transform: rotate(180deg); }
    .dropdown { position: absolute; right: 10px; top: 40px; z-index: 9999; background-color: $csyellow; min-width: 200px; border-radius: 5px; box-shadow: 4px 2px 14px 0 rgb(0 0 0 / 17%); visibility: hidden; opacity: 0; height: 0; transition: 0.4s opacity; }
    .dropdown-item { padding: 4px 12px; cursor: pointer; }
    .dropdown.show-menu { visibility: visible; opacity: 1; height: auto; }
  }
}
// =================== Header ===================

// =================== menu ===================
.app-component-wrapper  { display: flex; flex-wrap: wrap; background-color: #fbf9f2; }
.toolbar-wrapper { flex: 0 0 calc(100% - 350px); overflow-x: hidden; max-height: 100vh; }
.toolbar-wrapper-public { flex: 0 0 100%; }
.toolbar-wrapper-collapsed { flex: 0 0 calc(100% - 80px); transition-duration: 0.4s; }
.mainmenu {
  & { position: relative; height: 100vh; overflow-y: scroll; }
  .mat-drawer-inner-container { overflow: unset; display: flex; flex-direction: column; position: relative; }
  .cross-menu { position: absolute; top: 20px; right: 10px; font-size: 28px; cursor: pointer; }
  .closebtn, .menubtn { position: absolute; right: 15px; top: 40px; }
  .closebtn img, .menubtn img { width:40px; }

  &.mainmenu-collapsed {
    & { transition-duration: 0.4s; }
    & { visibility: visible !important; width: 20px; }
    .cs-menu a img { margin-right: 0px; }
    .cs-menu a { padding: 12px 15px 12px 15px; justify-content: center; font-weight: 400; }
    .cs-logo { visibility: hidden; }
  }
}

// .checkActiveMenu {

//   font-weight: bolder;
// }

// .cs-menu a.active {
//   font-weight: bolder;
// }

.cs-logo {
  & { text-align: left; line-height: normal; cursor: pointer; }
  .cs-logo-title {  color: $csyellow; font-size: 36px; letter-spacing: 0.3px; font-family: "butler-medium"; font-weight: 400; text-align: left; }
  .cs-logo-subtitle { color: #FFFFFF; font-family: $cs_montserrat_fonts; font-size: 12px; font-weight: 600; text-transform: uppercase; letter-spacing: 0.3px; }
}
.cs-menu {
  & { margin: 25px -30px 20px; flex: 1 0 auto; font-weight: 400; }
  ul { list-style: none; }
  li { margin: 0px 0px; }
  a { padding: 12px 15px 12px 32px; display: flex; flex-wrap: wrap; align-items: center; color: #fff; font-family: $cs_montserrat_fonts; font-size: 18px; font-weight: 400; text-transform: capitalize; cursor: pointer; transition:0.4s; text-decoration: none; }
  a img {
    & { width: 25px; margin-right: 20px; }
    &.active { display:none; }
    &.in-active { display:block; }
  }
  a.active {
    & { background-color: $csyellow; color:$themecolor1; }
    font-weight: bolder;
    img.active { display:block; }
    img.in-active { display:none; }
  }
  a:hover { background: $themeaccentcolor1 ; color: #fff; }
  a.active:hover { background: $csyellow; color: $themecolor1; }
  .inactive {
    & { opacity: 0.2; }
    a { cursor: auto; }
  }
}
.cs-copyright {
  & { text-align: center; line-height: 0; width: 100%; padding: 15px 0px 50px; background-color: $themecolor1; }
  img { width: 200px; }
}
// =================== menu ===================

// =================== tabs ===================
.cs-tabs {
  & { padding-bottom: 80px; }
  .mat-tab-labels { background: transparent; justify-content: center; }
  .mat-tab-group { margin-top: 40px; }
  .mat-ink-bar { display: none; }
  .mat-tab-header { border-bottom: none; }
  .mat-tab-header { border-bottom: none; }
  .mat-tab-label { font-weight:600; opacity: 1; border: 1px solid $themecolor1; height: 42px; min-width: 180px; margin: 0px 5px; transition:0.4s; }
  .mat-tab-label-active { font-weight:bolder;  }
  .mat-tab-label-content { font-size: 16px; font-weight: 600; text-transform: none; font-family: $cs_montserrat_fonts; }
  .mat-tab-label-content-active { font-weight: 800;  }
  
  // .mat-tab-label-content:visited {font-weight:bolder;}
  .mat-tab-label-content i { margin-right: 10px;  }
  .mat-tab-label:hover { background-color: $themecolor1; color: #fff; }
 
  .mat-tab-label.mat-tab-label-active { background-color: $csyellow; color: $themecolor1; border-color:$csyellow;  }
 
  .mat-tab-body-wrapper { max-width: 677px; margin: 30px auto 0px; width: 100%; overflow: unset; }
  .cs-tabs > .mat-tab-group > .mat-tab-body-wrapper > .mat-tab-body:nth-child(2).mat-tab-body-active { box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%); }
  .full-frame .mat-tab-body-wrapper { max-width: 100%; }
  &.no-box-shadow .mat-tab-body:nth-child(2).mat-tab-body-active { box-shadow: none; }
  // brand voice chart
  &.bvc-tabs, &.brand-p-tabs, &.voice-and-tone {
    .mat-tab-body-wrapper { max-width: 100%; }
    .mat-tab-body:nth-child(2) { max-width: 677px; margin: 0 auto; }
  }

  &.submissions-tab .mat-tab-body-active { box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%) !important; }

  &.brand-p-tabs, &.voice-and-tone {
    .question-content .question-block .mat-form-field-infix { padding: 8px 0 8px 0; }
    .question-content .question-block .mat-form-field-appearance-outline  .mat-form-field-infix { padding: 2px 0 8px 0; }
  }

  &.bold-version {
    .mat-tab-label { border-width: 2px; height: 45px; min-width: 195px; }
    .mat-tab-label-content { font-size: 18px; font-weight: 400; }
    .mat-tab-label-active .mat-tab-label-content { font-weight: 600; }
    .video-content { font-size: 18px; font-weight: 400; }
    .questions-wrapper { margin-bottom: 40px; }
    .question-instructions { width: 100%; text-align: center; margin-bottom: 20px; font-family: $cs_montserrat_fonts; font-size: 18px; font-weight: 400; }
    .question-heading { position: absolute; left: 0px; top: -80px; width: 100%; margin-bottom: 25px; text-align: center; font-size: 18px; font-weight: 400; font-family: $cs_montserrat_fonts; letter-spacing: 0.3px;  }
    .question-content .question-block .question-block-title { font-family: "butler-bold"; font-size: 22px; line-height: normal; }
    .question-content .question-block .question-block-title.voice-tone { font-family: $cs_montserrat_fonts; font-size: 15px; margin-top: 15px; }
    .question-content .question-block .question-block-label { margin-bottom: 10px; }
    .question-content .question-block .mat-form-field-infix { padding: 8px 0 8px 0; }
    .question-content .question-block .mat-form-field-appearance-outline  .mat-form-field-infix { padding: 2px 0 8px 0; }
    .question-content .question-block .mat-form-field-appearance-outline .mat-form-field-outline-start,
    .question-content .question-block .mat-form-field-appearance-outline .mat-form-field-outline-end { border-width: 2px; }
    .question-content .question-block .mat-form-field-appearance-outline .mat-form-field-outline-start {  border-radius: 2px 0 0 2px; }
    .question-content .question-block .mat-form-field-appearance-outline .mat-form-field-outline-end { border-radius: 0 2px 2px 0; }
    .prework-chip .mat-standard-chip { font-weight: 600; margin: 4px 4px 8px; }
    .prework-chip .mat-chip-list-wrapper { margin: -12px 0px 0px; }
    .thank-you {
      & { background-color: #fff; padding: 40px 20px 60px; text-align: center; }
      .heading { background-color: transparent; font-family: "Butler-Bold"; font-size:30px; }
      span.material-icons { margin-right: 0px; font-size: 80px; color: $csyellow; }
      .content { font-weight: 600; font-size: 18px; padding: 0px 15px 15px; }
      a { color: #f2df3a; text-decoration: underline; }
    }
   &> .mat-tab-group > .mat-tab-body-wrapper > .mat-tab-body:nth-child(2).mat-tab-body-active  { margin-top: 80px; overflow: visible; }
   .mat-tab-body-wrapper, .main-frame { max-width: 767px; }
   .arrows-wrapper { float: right; margin-top: -24px; }
  }

  .mat-tab-body.mat-tab-body-active {
    -webkit-animation: fadeInDown 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeInDown 1s; /* Firefox < 16 */
        -ms-animation: fadeInDown 1s; /* Internet Explorer */
         -o-animation: fadeInDown 1s; /* Opera < 12.1 */
            animation: fadeInDown 1s;
  }

  @keyframes fadeInDown {
    from { opacity: 0; transform: translate3d(0,-80px,0) }
    to   { opacity: 1; transform: translate3d(0,0,0); }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadeInDown {
    from { opacity: 0; transform: translate3d(0,-80px,0); }
    to   { opacity: 1; transform: translate3d(0,0,0); }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadeInDown {
    from { opacity: 0; transform: translate3d(0,-80px,0); }
    to   { opacity: 1; transform: translate3d(0,0,0); }
  }

  /* Internet Explorer */
  @-ms-keyframes fadeInDown {
    from { opacity: 0; transform: translate3d(0,-80px,0); }
    to   { opacity: 1; transform: translate3d(0,0,0); }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadeInDown {
    from { opacity: 0; transform: translate3d(0,-80px,0); }
    to   { opacity: 1; transform: translate3d(0,0,0); }
  }

  .start-select, .continue-select, .stop-select { font-weight: 600; border-radius: 5px; padding: 0; text-transform: capitalize; }
  .start-select { background-color: #c7f3b9; }
  .continue-select { background-color: #ffd89f; }
  .stop-select{ background-color: #fcabab; }


  &.personification-page {
    .mat-tab-group > .mat-tab-body-wrapper > .mat-tab-body:nth-child(2).mat-tab-body-active { margin-top: 0px; overflow: auto; }
    .question-content {
      & { box-shadow: none; background-color: transparent;  padding: 0px 0px; }
      .mat-stepper-horizontal {
        & { background-color: transparent;  }
        .mat-horizontal-stepper-header .mat-step-icon { margin-bottom: 0px; background-color: $themecolor1; }
        .mat-horizontal-stepper-header-container { max-width: 500px; margin: 0 auto; }
        .mat-step-icon { height: 35px; width: 35px; font-size: 24px; background-color: #3f51b5; }
        .mat-stepper-horizontal-line { border-top-width: 4px; margin: 0px -16px 0; }
        .mat-horizontal-content-container { margin-top: 20px; }
      }
      .persona-details {
        & { text-align: center; color: $themecolor1; font-family: $cs_montserrat_fonts; }
        .persona-name { font-size: 18px; font-weight: 700; }
        .persona-description { font-size: 14px; font-weight: 600; }
      }
    }
    .tab-group-wrapper {
      & { background-color: #ffffff; padding: 40px 50px; margin-top: 25px; }
      .tab-group-heading { margin-bottom: 20px; text-align: center; color: #9b9b9b; font-size: 14px; font-family: $cs_montserrat_fonts; font-weight: 500; }
      .mat-tab-label { height: 32px; min-width: 90px; margin: 0px 1px; color: #fff; background-color: $themecolor1; flex: 1 0 auto; font-weight: 700; transition: 0; }
      .mat-tab-group { margin-top: 5px; }
      .mat-tab-label.mat-tab-label-active {font-weight: 900; background-color: $csyellow; color: $themecolor1; }
      .mat-tab-label-active .mat-tab-label-content { font-weight: 700; }
      .mat-tab-body-wrapper { max-width: 100%; margin: 0px 0px; width: 100%; }
      .mat-tab-header { margin-bottom: 50px; }

    }
    .actionBtn { text-align: right; margin-top: 15px; }
    .actionBtn .next-button { min-width: 150px; height: 50px; background: $csyellow; color: $themecolor1 !important; font-weight: 700; }
  }

  &.brand-voice-chart {
    .mat-tab-group > .mat-tab-body-wrapper > .mat-tab-body:nth-child(2).mat-tab-body-active { margin-top: 0px; overflow: auto; }
  }

  &.brand-advocacy-page {
    .mat-tab-group > .mat-tab-body-wrapper > .mat-tab-body:nth-child(2).mat-tab-body-active { margin-top: 0px; overflow: auto; }
  }

  &.brand-p-tabs {
    .mat-tab-group > .mat-tab-body-wrapper > .mat-tab-body:nth-child(2).mat-tab-body-active { margin-top: 0px; overflow: auto; }
  }

  .video-content { text-align: center; font-size: 15px; font-weight: 300; font-family: $cs_montserrat_fonts; letter-spacing: 0.3px;  }
  .video-frame { margin-top: 30px; }
  .question-content {
    & { box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5); background-color: #ffffff; padding: 40px 50px;}
    .top-input-field {
      &.left { text-align: left; }
      & {text-align: right; font-size: 16px; font-family: $cs_montserrat_fonts; }
      .mat-input-element { font-family: $cs_montserrat_fonts; font-size: 15px; }
    }

    .question-block {
      .question-block-title { color:$themecolor1; font-family: $cs_montserrat_fonts; font-size: 16px; margin-bottom: 5px; }
      .question-block-label { color:$themecolor1; font-family: $cs_montserrat_fonts; font-size: 12px; margin-bottom: 5px; }
      .mat-form-field { width: 100%; }
      textarea { border: none; height:80px; }
      .mat-form-field-infix { padding: 5px 0 5px 0; }
      .mat-input-element { color:$themecolor1; font-family: $cs_montserrat_fonts; font-size: 15px; width: 100%; }

      .mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-end { border-color: $themecolor1; }
      .mat-form-field-appearance-outline .mat-form-field-outline-start { border-radius: 0px 0 0 0px; }
      .mat-form-field-appearance-outline .mat-form-field-outline-end { border-radius: 0 0px 0px 0; }
      .mat-form-field-wrapper { padding-bottom: 15px; }
    }
    .category-question-wrapper .question-block {
      & { margin-bottom: 20px; }
      .category-question-title { font-family:$cs_montserrat_fonts; color:$themecolor1; font-size: 18px; font-weight: 600; }
      .mat-form-field { margin-left: 30px; width: calc(100% - 30px); }
      .prework-chip .mat-chip-list-wrapper { margin-left: 30px; }
      .category-question-title {
        .mat-form-field { width: 300px; margin-left: 0px; }
        .mat-form-field-infix { padding: 5px 0px 15px; border-top: 0px; }
        .mat-form-field-underline { height: 2px; background-color:$themecolor1; }
        .mat-input-element { font-weight: 600; }
        .mat-form-field-label { color: #bebebe; font-family: $cs_montserrat_fonts; font-size: 15px; }
      }
    }
    .question-tabs {
      // .mat-tab-header-pagination { display:flex; }
      .mat-tab-label { height: 32px; min-width: 90px; margin: 0px 1px; color: #fff; background-color: $themecolor1; flex: 1 0 auto; }
      .mat-tab-group { margin-top: 5px; }
      .mat-tab-label.mat-tab-label-active { background-color: $csyellow; color: $themecolor1; }
      .mat-tab-body-wrapper { max-width: 100%; margin: 0px 0px; width: 100%; }
      .question-tab-title { color:$themecolor1; font-family: $cs_montserrat_fonts; font-size: 16px; font-weight: 600; margin-top: 15px; }
      .question-tab-content { color:$themecolor1; font-family: $cs_montserrat_fonts; font-size: 12px; font-weight: 600; margin-bottom: 35px; }
      .actionBtn {
        & { text-align: right; }
        .next-button { min-width: 150px; height: 50px; }
      }
      .mat-tab-body.mat-tab-body-active {
        -webkit-animation: none; /* Safari, Chrome and Opera > 12.1 */
           -moz-animation: none; /* Firefox < 16 */
            -ms-animation: none; /* Internet Explorer */
             -o-animation: none; /* Opera < 12.1 */
                animation: none;
      }
    }
    .dropdown {
      & { margin-top: 35px; }
      .dropdown-title { font-size: 16px; font-family: $cs_montserrat_fonts; font-weight: 500; margin-bottom: 15px; }
      .actionBtn {
        & { text-align: right; }
        .next-button { min-width: 150px; height: 50px; }
      }
      .dropdown-wrapper {
        & { display: flex; flex-wrap: wrap; align-items: center; margin-bottom: 10px; background-color: #fbf9f3; }
        .dropdown-wrapper-left { flex: 1 0 auto; color:$themecolor1; font-size: 14px; font-family: $cs_montserrat_fonts; padding-left: 10px; }
        .dropdown-wrapper-left input { padding: 0px; margin: 0px; border: 0; background-color: transparent; width: 100%; height: 40px; outline: none; }
        .dropdown-wrapper-right { flex: 0 0 125px; }
        mat-select {
          & { background-color: #9b9b9b; height: 40px; }
          .mat-select-trigger { height: 40px; }
          .mat-select-value { height: 40px; }
          .mat-select-placeholder { display: flex; height: 40px; align-items: center; color: #fff; justify-content: center; font-family: $cs_montserrat_fonts; font-weight: 700; font-size: 16px; }
          .mat-select-arrow-wrapper { padding-right: 5px; }
          .mat-select-arrow { color: #fff; }
          .mat-select-value-text { height: 40px; display: block; }
          .mat-select-value-text span { display: flex; height: 40px; align-items: center; color: $themecolor1; justify-content: center; font-family: $cs_montserrat_fonts; font-weight: 700; font-size: 16px; }
          &.start-select { background-color: #c7f3b9; }
          &.continue-select { background-color: #ffd89f; }
          &.stop-select { background-color: #fcabab; }
          &.start-select, &.continue-select, &.stop-select {
            .mat-select-arrow { color: $themecolor1; }
          }
        }
      }
      .add-another {
        & { margin-bottom: 20px; }
        .csbutton { text-transform: capitalize; font-size: 14px; border-radius: 0px; }
        span.material-icons { font-size: 18px; }
      }
    }
    .mat-stepper-horizontal {
      .mat-horizontal-stepper-header-container { padding: 0px 30px; }
      .mat-horizontal-content-container { padding: 0px; margin-top: 30px; }
      .mat-horizontal-stepper-header { background-color: transparent; z-index: 9; padding: 0 0px; flex-direction: column; align-items: center; justify-content: center; text-align: center; }
      .mat-horizontal-stepper-header .mat-step-icon { margin-right: 0px; margin-bottom: 8px; background-color: $themecolor1; }
      .mat-step-icon { background-color: transparent; height: 30px; width: 30px; font-weight: 600; font-family: $cs_montserrat_fonts; font-size: 18px; }
      .mat-step-header .mat-step-icon-selected { background-color: $csyellow !important; color:$themecolor1; }
      .mat-step-icon .mat-icon { height: 30px; width: 30px; font-size: 30px; background-color: #fafafa; }
      .mat-step-text-label { font-weight: 500; font-family: $cs_montserrat_fonts; font-size: 15px; }
      // .mat-step-label-active .mat-step-text-label { font-weight: 700; }
      .mat-stepper-horizontal-line { border-top-width: 2px; border-top-color:$themecolor1; margin: -28px -16px 0; }
    }
    .mat-accordion {
      .mat-expansion-panel { margin-bottom: 12px; box-shadow: none; }
      .mat-expansion-panel-header { padding: 0 20px; }
      .mat-expansion-panel-header { height: 42px; background-color: #eeeeee; }
      .mat-expansion-indicator { margin-top: -2px; }
      .mat-expansion-panel-header-title { font-family: $cs_montserrat_fonts; }
      .mat-expansion-panel-body { padding: 20px 0px 0px; margin-bottom: -9px; }
      .mat-expansion-panel-header.mat-expanded {
        & { background-color: $themecolor1; }
        .mat-expansion-panel-header-title { color:#fff; }
        .mat-expansion-indicator::after { color: #fff; }
        .mat-expansion-indicator { margin-top: 4px; }
      }
    }
    .brand-voice-chart-tabs {
      .actionBtn {
        & { text-align: right; }
        .next-button { min-width: 150px; height: 50px; font-weight: 700; background: $csyellow; color: $themecolor1 !important; }
      }
      .mat-tab-label { height: 32px; min-width: 90px; margin: 0px 1px; color: #fff; background-color: $themecolor1; flex: 1 0 auto; }
      .mat-tab-group { margin-top: 5px; }
      .mat-tab-label.mat-tab-label-active { background-color: $csyellow; color: $themecolor1; }
      .mat-tab-body-wrapper { max-width: 100%; margin: 0px 0px; width: 100%; }
      .mat-tab-header { margin-bottom: 50px; }
      .questions-wrapper { margin-bottom: 20px; }
    }
    .brand-personality {
      .actionBtn {
        & { text-align: right; }
        .next-button { min-width: 150px; height: 50px;  }
      }
      .main-title { font-size: 16px; font-family: $cs_montserrat_fonts; font-weight: 500; margin-bottom: 15px; }
      .questions-wrapper { margin-bottom: 20px; }
    }
    .voice-and-tone {
      .actionBtn {
        & { text-align: right; margin-top: 15px; }
        .next-button { min-width: 150px; height: 50px;  }
      }
      .main-title { font-size: 16px; font-family: $cs_montserrat_fonts; font-weight: 500; margin-bottom: 15px; }
    }
    .question-group {
      & { border: 1px solid #eee; padding: 15px 15px 25px;  margin-bottom: 85px; }
      .question-block { display: flex; flex-wrap: wrap; align-items: center; }
      .question-block-title { flex: 0 0 calc(50% - 15px); margin-right: 15px; font-size: 15px; }
      .mat-form-field { flex: 0 0 50%; }
      .question-block .mat-form-field-wrapper { padding-bottom: 0px; }
      .mat-form-field-appearance-legacy .mat-form-field-underline { bottom: 0px; }
      .question-block .mat-form-field-infix { padding: 2px 0 2px 0; }
    }
    .golden-pyramid-wrapper {
      .actionBtn { text-align: right; margin-top: 15px; }
      .actionBtn .next-button { min-width: 150px; height: 50px; background: $csyellow; color: $themecolor1 !important; font-weight: 700; }
    }
    .problem-priority-wrapper, .imagination-destination-wrapper, .brand-values-wrapper, .brand-personality-wrapper {
      .actionBtn { margin-top: 50px; }
      .actionBtn .next-button { min-width: 150px; height: 50px; background: $csyellow; color: $themecolor1 !important; font-weight: 700; }
      .actionBtn .blue-button { background-color: $themecolor1 !important; color: #fff !important; }
      .mat-tab-label { height: 24px; min-width: 24px; margin: 0px 4px; padding: 0px 5px; color: $themecolor1; background-color: #fff; }
      .mat-tab-group { margin-top: 0px; }
      .mat-tab-label.mat-tab-label-active { background-color: $csyellow; color: $themecolor1; }
      .mat-tab-body-wrapper { margin: 15px auto 0px; }
      .mat-tab-label-content { font-size: 14px; }
      .mat-horizontal-stepper-header-container { display: none !important; }
      .add-another {
        & { display: flex; align-items: center; justify-content: center; flex-wrap: wrap; }
        .csbutton { font-size: 14px; text-transform: none; height: 24px; line-height: normal; font-weight: 600; margin-left: 2px; }
        .csbutton .material-icons { font-size: 12px; margin-right: 5px; font-weight: 600; }
        .footer-tab { height: 20px; width: 10px; margin: 0px 4px 4px; padding: 0px 5px; color: $themecolor1; background-color: #fff; display: flex; align-items: center; justify-content: center; font-family:$cs_montserrat_fonts; border: 2px solid $themecolor1; font-size: 14px; font-weight: 600; cursor:pointer; }
        .footer-tab.active { background-color: $csyellow; border-color: $csyellow; }

      }
    }

    .problem-priority-wrapper {
      .actionBtn { text-align: right; }
    }
    
    .brand-messaging-wrapper {
      .actionBtn { text-align: right; margin-top: 15px; }
      .actionBtn .next-button { min-width: 150px; height: 50px; background: $csyellow; color: $themecolor1 !important; font-weight: 700; }
      .actionBtn .blue-button { background-color: $themecolor1 !important; color: #fff !important; }
      .mat-stepper-horizontal .mat-stepper-horizontal-line { margin: -28px -28px 0; }
    }
    .imagination-destination-wrapper {
      .imagination-numbers { background-color: $themecolor1 !important; font-family:$cs_montserrat_fonts; padding: 5px 10px;width: 50px; color: #fff; text-align: center; }
      .imagination-arrows { background-color: $themecolor1 !important; font-family:$cs_montserrat_fonts; padding: 3px 4px 4px; width: 22px; height: 22px; color: #fff; text-align: center;float: left;cursor: pointer; }
      .inactive-arrow { background-color: #dcd7ca !important; cursor: default; }
    }
    .brand-values-wrapper, .brand-personality-wrapper {
      .brand-values-numbers, .brand-personality-numbers { background-color: $themecolor1 !important; font-family:$cs_montserrat_fonts; padding: 2px 6px; width: 45px; color: #fff; text-align: center; font-size: 14px; font-weight: 600; }
      .brand-values-arrows, .brand-personality-arrows { background-color: $themecolor1 !important; font-family:$cs_montserrat_fonts; width: 22px; height: 22px; color: #fff; text-align: center;float: left;cursor: pointer; }
      .inactive-arrow { background-color: #dcd7ca !important; cursor: default; }
      .actionBtn { display: flex; align-items: center; justify-content: space-between; }
    }
   }
   .example-content {
    & { max-width: 840px; margin: 30px auto; }
    th { color: $themecolor1; font-size: 13px; font-family: $cs_montserrat_fonts; font-weight: 600; padding: 12px 12px; border-bottom: 0px; border-left:1px solid #dcd7ca; border-right:1px solid #dcd7ca; width: 20%; background-color: #FAEFCB; }
    td { color: $themecolor1; font-size: 13px; font-family: $cs_montserrat_fonts; font-weight: 300; padding: 12px 12px; border-left: 1px solid #dcd7ca; border-right: 1px solid #dcd7ca; vertical-align: top; white-space: pre-line; border-bottom: 1px solid #dcd7ca; width: 20%; }
    table { margin-bottom: 0px; width: 100%; }
    tr {width: 100%;}
    tr td:first-child, tr td:nth-child(2) { vertical-align: middle; }
    tr td:first-child { font-weight: 600; }
    tr:nth-child(even) { background-color:#fff; }
  }
   .thank-you {
     & { color: $themecolor1; font-size: 16px; font-family: $cs_montserrat_fonts; }
    .heading { padding: 12px 15px; background-color: $csyellow; display: flex; align-items: center; justify-content: center; }
    span.material-icons { margin-right: 5px; }
    .content { padding: 15px 15px; background-color: #fff; text-align: center; }
    a { color: #ceb037; cursor: pointer; }
  }

  .golden-pyramid-container {
    .mat-stepper-horizontal {
      & { background-color: transparent; }
      .mat-horizontal-stepper-header-container { padding: 0px 30px; }
      .mat-horizontal-content-container { padding: 0px; margin-top: 30px; }
      .mat-horizontal-stepper-header { background-color: transparent; z-index: 9; padding: 0 0px; flex-direction: column; align-items: center; justify-content: center; text-align: center; }
      .mat-horizontal-stepper-header .mat-step-icon { margin-right: 0px; margin-bottom: 8px; background-color: $themecolor1; }
      .mat-step-icon { background-color: transparent; height: 30px; width: 30px; font-weight: 600; font-family: $cs_montserrat_fonts; font-size: 18px; }
      .mat-step-header .mat-step-icon-selected { background-color: $csyellow !important; color:$themecolor1; }
      .mat-step-icon .mat-icon { height: 30px; width: 30px; font-size: 30px; background-color: #fafafa; }
      .mat-step-text-label { font-weight: 500; font-family: $cs_montserrat_fonts; font-size: 15px; }
      .mat-step-label-selected .mat-step-text-label { font-weight: 700; }
      .mat-stepper-horizontal-line { border-top-width: 2px; border-top-color:$themecolor1; margin: -28px -16px 0; }
    }
  }

  // chip
.prework-chip {
  .mat-standard-chip { border-radius: 5px; font-family: "montserrat", sans-serif; height: auto; color:$themecolor1; }
  .mat-chip-list-wrapper { margin: -12px 0px 0px; }
}

 &.circle-initials {
  .mat-tab-group { position: relative; }
  .mat-tab-label { height: 35px; width: 35px; border-radius: 100%; padding: 0px; min-width: 30px; opacity: 1; border:2px solid $themecolor1; margin-right: 5px; font-size: 14px; font-weight: 600; }
  .mat-tab-labels { background: transparent; justify-content: flex-end; }
  mat-ink-bar.mat-ink-bar { display: none;  }
  .mat-tab-label-active { background-color: $themecolor1; color: #fff; }
  .mat-tab-header { position: static; border: none; }
 }

.response-table {
  & { padding: 30px 0px 30px; }
  th { color: $themecolor1; font-size: 15px; font-family: "montserrat", sans-serif; font-weight: 600; padding: 10px 10px 0px; border-bottom: 0px; border-right:1px solid #d1d1d1; width: 20%;vertical-align: top; }
  td { color: $themecolor1; font-size: 12px; font-family: "montserrat", sans-serif; font-weight: 500; padding: 25px 10px; border-bottom: none; border-right: 1px solid #d1d1d1; vertical-align: top; white-space: pre-line; border-bottom: 1px solid #d1d1d1; width: 20%; }
  td:last-child { border-right:none; }
  th:last-child { border-right:none; }
  table { margin-bottom: 0px; width: 100%; }
  tr {width: 100%;}
  tr td:first-child { vertical-align: top; }
  .pp-checkbox { border-right: none; width: 5%; }
  span.icon { display: block; height: 2px; margin: 5px 0; background-color: #979797; }
  .icons-wrapper { flex: 0 0 30px; }
  .checkbox-icons-wrapper {
    & {display: flex; flex-wrap: wrap; align-items: center; }
    mat-checkbox { border-radius: 100%; color: $themecolor1; box-shadow:0 1px 5px 0 rgba(0, 0, 0, 0.17); width: 21px; height: 21px; margin-right: 10px; border: 2px solid $themecolor1; }
    .mat-checkbox-layout { margin: 0; }
    .mat-checkbox-label { font-weight: 600; font-family:$mainfont; font-size: 14px; display: flex; align-items: center; white-space: normal; }
    mat-checkbox:last-child { margin-bottom: 0px; }
    .mat-checkbox-inner-container { display: none; }
    .circle { width: 21px; height: 21px; flex: 0 0 21px; border-radius: 100%; visibility: hidden; }
    .circle span { color: $themecolor1; background: $themecolor2; width: 21px; height: 21px; border-radius: 100%; display: flex; justify-content: center; align-items: center; }
    mat-checkbox.mat-checkbox-checked .circle { visibility: visible; }
  }
  .disabledOverlay { opacity: 0.5; }

  }

  &.new-submissions-design.brand-advocacy-submissions {
    .mat-tab-group .response-table {
      th { font-size: 14px; background-color: #d8d8d8; padding: 2px 15px 2px; }
      td { border-color: #979797; font-size: 14px; font-weight: 300; }
      th:first-of-type, td:first-of-type { width: 5%; }
    }
    .persona-details {
      & { max-width: 600px; margin: 18px auto 0px; color:$themecolor1; font-family:$cs_montserrat_fonts; font-weight: 600; line-height: normal; }
      .persona-name { font-size:18px; }
      .persona-description { font-size:12px; }
    }
    .question-content { padding: 0px 15px; }
    .mat-tab-label { height: 32px; min-width: 90px; margin: 0px 1px; color: #fff; background-color: $themecolor1; }
    .mat-tab-label.mat-tab-label-active { background-color: $csyellow; color: $themecolor1; }
    .member { cursor: pointer; flex: 0 0 35px; height: 35px; width: 35px; border-radius: 100%; background-color: $themecolor1; margin-right: 10px; font-size: 14px; font-weight: 600; display: flex; align-items: center; justify-content: center; color: #fff; }
    .response-table { padding: 50px 0px 0px; }

  }

  &.new-submissions-design {
    .submissions-title { text-align: center; color: $themecolor1; font-size: 30px; font-family: "butler-bold"; margin-top: 15px; }
    .mat-tab-group {
      & { margin-top: 25px; box-shadow: 4px 2px 14px 0 rgb(0 0 0 / 17%); background-color: #ffffff; border-radius: 5px; padding: 25px; }
      .mat-tab-body-wrapper { margin: 0px auto 0px; }
      .question-content { box-shadow: none; background-color: transparent; padding: 0px 0px; }
      .mat-tab-body-active { box-shadow: none !important; }
      .response-table th { font-size: 18px; }
      .response-table td { font-size: 14px; }
      .mat-tab-label-content:active { font-weight: bold; }
    }
    &.circle-initials .mat-tab-group .mat-tab-label-content { font-weight: 600; }
    .question-content .questions-wrapper { margin-top: 25px; padding: 0px 15px 40px; }
    .question-content .question-block .question-block-title { font-family: "butler-bold"; font-size: 24px; line-height: normal; }
    .initial { height: 35px; width: 35px; border-radius: 100%; font-size: 14px; font-weight: 600; background-color: $themecolor1; color: #fff; display: flex; align-items: center; justify-content: center; cursor: pointer; }

  }

  &.golden-pyramid-submissions {
    .question-content { box-shadow: none; background-color: transparent; }
    .mat-stepper-horizontal { background-color: transparent; }
    .mat-tab-group { margin-top: 0px; }
    .mat-horizontal-content-container { overflow: visible; }
    .question-content .mat-stepper-horizontal .mat-step-text-label { font-size: 16px; }
    .question-results {
      & { margin-top: 15px; }
      .result { color: $themecolor1; font-size: 14px; font-weight: 600; font-family: $cs_montserrat_fonts; background-color: #d8d8d8; margin-bottom: 10px; margin-right: 10px; padding: 5px 10px; border-radius: 4px; display: inline-block; max-width: 600px; }
    }
  }

  &.problem-priority-submissions {
    .response-table th:first-of-type, .response-table td:first-of-type { width: 5%; font-size: 18px; font-weight: 600; }
    .response-table td { padding: 25px 15px; }
    .response-table th { padding: 10px 15px 0px; }
  }

  &.imagination-destination-submissions, &.brand-values-submissions, &.brand-personality-submissions {
    .question-content { margin-top: 30px; }
    .imagination-destination-submissions-wrapper, .brand-values-submissions-wrapper, .brand-personality-submissions-wrapper {
      & { position: relative; }
      .adjust-margin { margin-top: -23px !important; }
      .mat-horizontal-stepper-header-container { display: none; }
      .imagination-numbers, .brand-values-numbers, .brand-personality-numbers { background-color: $themecolor1; font-family: $cs_montserrat_fonts; padding: 2px 5px; width: 50px; color: #fff; text-align: center; font-size: 14px; font-weight: 600; }
      .mat-horizontal-content-container { margin-top: 15px; }
      .imagination-arrows, .brand-values-arrows, .brand-personality-arrows { background-color: $themecolor1; font-family: $cs_montserrat_fonts; width: 20px; height: 20px; color: #fff; display: flex; align-items: center; justify-content: center; cursor: pointer; }
      .imagination-arrows .material-icons, .brand-values-arrows .material-icons, .brand-personality-arrows .material-icons { font-size: 30px; }
      .actionBtn { display: flex; flex-wrap: wrap; justify-content: space-between; flex: 0 0 42px; width: 42px; position: absolute; right: 0px; margin-top: 15px; }
      .question-block-title { padding-right: 50px; }
      .brand-personality-arrows, .brand-values-arrows, .imagination-arrows { background-color: $csyellow; color:$themecolor1; }
      .imagination-arrows.disabled, .brand-values-arrows.disabled, .brand-personality-arrows.disabled  { background-color: #c7c7c7; pointer-events: none; opacity: 0.5; }
      .responses-wrapper {
        & { margin-top: 30px; font-family: $cs_montserrat_fonts; }
        .response { display: flex; flex-wrap: wrap; margin-bottom: 30px; }
        .member { cursor: pointer; flex: 0 0 35px; height: 35px; width: 35px; border-radius: 100%; background-color: $themecolor1; margin-right: 10px; font-size: 14px; font-weight: 600; display: flex; align-items: center; justify-content: center; color: #fff; }
        .response-result { font-size: 14px; font-weight: 500; flex: 0 0 calc(100% - 45px); }
        .result { display: inline-block; color:$themecolor1; margin: 0px 4px; background-color: #d8d8d8; padding: 4px 10px; border-radius: 5px; font-size: 14px; font-weight: 600; }
      }
     }
  }

  &.keywords-submissions {
    .mat-step-header .mat-step-icon .mat-icon { font-weight: 600; }
    .question-content { box-shadow: none; background-color: transparent; }
    .mat-stepper-horizontal { background-color: transparent; }
    .mat-horizontal-content-container { max-width: 100%; }
    .mat-step-icon .mat-icon { font-size: 34px !important; border: 3px solid #fafafa; padding-right: 3px;  }
    .question-content .mat-stepper-horizontal .mat-step-text-label { font-weight: 600; font-size: 16px; margin: 0 auto; color: #202945 }
    .question-block { box-shadow: 4px 2px 14px 0 rgb(0 0 0 / 17%); background-color: #ffffff; border-radius: 5px; padding: 60px; }
    .mat-stepper-horizontal .mat-stepper-horizontal-line { margin: -31px -30px 0; }
    .mat-stepper-horizontal .mat-horizontal-stepper-header-container { max-width: 600px; margin: 0 auto;  }
    .mat-stepper-horizontal .mat-step-header .mat-step-icon-selected { background-color: #c7f4b9; }
    .mat-step-header .mat-step-label-selected .mat-step-text-label { font-weight: 800; }
    .responses-wrapper {
      & { font-family: $cs_montserrat_fonts; }
      .response { display: flex; flex-wrap: wrap; margin-bottom: 30px; }
      .member { cursor: pointer; flex: 0 0 35px; height: 35px; width: 35px; border-radius: 100%; background-color: $themecolor1; margin-right: 10px; font-size: 14px; font-weight: 600; display: flex; align-items: center; justify-content: center; color: #fff; }
      .response-result { font-size: 14px; font-weight: 500; flex: 0 0 calc(100% - 45px); }
      .result { display: inline-block; color: $themecolor1; margin: 0px 4px 4px 0; background-color: #d8d8d8; padding: 4px 10px; border-radius: 5px; font-size: 14px; font-weight: 600; }
    }
  }

  &.brand-messaging-submissions {
    .mat-tab-group { margin-top: 40px; }
    .brand-messaging-title { font-size: 30px;  text-align: center; color:$themecolor1; font-family: "butler-bold"; margin-top: 20px; }
    .response-wrapper {
      & { margin: 40px 0px; padding: 0px 70px; }
      .response-member { color:$themecolor1; font-size:18px; font-family: $cs_montserrat_fonts; font-weight: 600; }
      .response-content { padding: 10px 15px 0px; color:$themecolor1; font-size:15px; font-family: $cs_montserrat_fonts; font-weight: 500; line-height: 170%; }
    }
   .mat-tab-label.mat-tab-label-active { background-color: transparent; border-color: $themecolor1;  }
   .mat-tab-label { min-width: auto; padding: 0 0px; border: none; border-bottom: 3px solid transparent; height: auto; margin: 0px 20px; }
   .mat-tab-label-content { font-size: 18px; font-weight: 600; }
   .mat-tab-label:hover { background-color: transparent; color: $themecolor1; }
  }

  &.brand-voice-chart-submissions {
    .mat-tab-label { height: 32px; min-width: 90px; margin: 0px 1px; color: #fff; background-color: $themecolor1; }
    .mat-tab-label.mat-tab-label-active { background-color: $csyellow; color: $themecolor1; }
    .mat-tab-header { margin-top: 20px; margin-bottom: 30px; }
    .response-table th:first-of-type, .response-table td:first-of-type { width: 5%; font-size: 18px; font-weight: 600; }
  }

  &.personification-submissions {
    .question-content {
      & { box-shadow: none; background-color: transparent;  padding: 0px 0px; }
      .mat-stepper-horizontal {
        & { background-color: transparent;  }
        .mat-horizontal-stepper-header .mat-step-icon { margin-bottom: 0px; }
        .mat-horizontal-stepper-header-container { max-width: 500px; margin: 0 auto; }
        .mat-step-icon { height: 35px; width: 35px; font-size: 24px; }
        .mat-stepper-horizontal-line { border-top-width: 4px; margin: 0px -16px 0; }
        .mat-horizontal-content-container { margin-top: 20px; }
      }
      .persona-details {
        & { text-align: center; color: $themecolor1; font-family: $cs_montserrat_fonts; }
        .persona-name { font-size: 18px; font-weight: 700; }
        .persona-description { font-size: 14px; font-weight: 600; }
      }
    }
    .mat-tab-label { height: 32px; min-width: 90px; margin: 0px 1px; color: #fff; background-color: $themecolor1; }
    .mat-tab-label.mat-tab-label-active { background-color: $csyellow; color: $themecolor1; font-weight:900; }
    

    .mat-tab-header { margin-top: 20px; margin-bottom: 30px; }
    .question-content .question-block .question-block-title { font-family: $cs_montserrat_fonts; font-size: 18px; font-weight: 700; }
    .question-results { margin-top: 20px; padding: 0px 25px; }
    .response { display: flex; flex-wrap: wrap; margin-bottom: 10px; }
    .member { font-family: $cs_montserrat_fonts; cursor: pointer; flex: 0 0 35px; height: 35px; width: 35px; border-radius: 100%; background-color: $themecolor1; margin-right: 10px; font-size: 14px; font-weight: 600; display: flex; align-items: center; justify-content: center; color: #fff; }
    .response-result { line-height: 25px; font-family: $cs_montserrat_fonts; font-size: 14px; font-weight: 600; flex: 0 0 calc(100% - 65px); background-color: #d8d8d8; border: 4px; padding: 5px 10px; }
  }

  &.segmentations-submissions {
    .segmentation-table {  margin-top: 25px; box-shadow: 4px 2px 14px 0 rgb(0 0 0 / 17%); background-color: #ffffff; border-radius: 5px; padding: 25px; }
    .segmentation-table-title { text-align: center; color: $themecolor1; font-size: 30px; font-family: "butler-bold"; margin-bottom: 20px; line-height: normal; }
    .category-wrapper {
      &:last-child { margin-bottom:0px; }
      & { display: flex; align-items: center; flex-wrap: wrap; border-radius: 5px; border: solid 1px #e1e1e1; padding: 12px; margin-bottom: 15px; }
      .initials { cursor: pointer; background-color: $themecolor1; color: #fff; border-radius: 100%; height: 35px; width: 35px; font-size: 16px;font-weight: 600; display: flex; justify-content: center; align-items: center; margin-right: 20px; flex: 0 0 35px; }
      .category-title { font-size: 20px; font-weight: 600; flex: 0 0 210px; color:$themecolor1; margin-right: 20px; }
      .segment-wrapper { display: flex; flex-wrap: wrap; flex: 0 0 calc(100% - 285px); margin-bottom: -5px; }
      .segment { background-color: $themecolor1; color: #fff; margin-right: 5px; border-radius: 20px; padding: 2px 15px; font-size: 16px; margin-bottom: 5px; font-weight: 500; }
    }
  }

  &.segmentation-page {
    .question-heading { margin-bottom: 50px; }
    .segmentation-wrapper {
      .actionBtn { text-align: right; margin-top: 50px; }
      .actionBtn .next-button { min-width: 150px; height: 50px; font-weight: 700; }
      .add-another {
        .csbutton { font-size: 14px; text-transform: none; height: 30px; line-height: normal; font-weight: 600; margin-left: 2px; background: $themecolor1; color: #fff !important; }
        .csbutton .material-icons { font-size: 12px; margin-right: 5px; font-weight: 600; }
      }
    }
  }

}


// =================== tabs ===================

// =================== login ===================

.audience-login {
  & { background-color: #1f2a44; height: 100vh; }
  .loginheader { padding-top: 60px; text-align: center; }
  .header-text { text-align: center; color: #fff; font-size: 36px; font-weight: bold; line-height: 36px; padding-top: 40px; font-family: "butler-bold"; position: static; margin: 0; width: 100%; }
  .footer-text { text-align: center; color: #fff; font-family: "butler-bold"; font-size: 24px; margin-bottom: 20px; }
  .corner-store-logo img { width: 200px; }
  .matspinner { min-height: 20%; }
  form { padding-top: 60px; margin-bottom: 80px;  width: 250px; margin: 0 auto; }
  mat-form-field { width: 100%; background-color: transparent; }
  .mat-form-field-appearance-outline .mat-form-field-outline { background-color: #fff; border-radius: 2px;}
  .mat-form-field-appearance-outline .mat-form-field-outline-end { border-radius: 0 2px 2px 0; }
  .mat-form-field-appearance-outline .mat-form-field-outline-start { border-radius: 2px 0 0 2px; }
  .login-input .mat-form-field-infix { padding: 8px 0px 14px; }
  .login-select .mat-form-field-infix { margin-top: -10px; padding: 14px 0; }
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label { transform: translateY(-1.70em) scale(.65); color: #fff !important }
  .mat-form-field-infix { font-family:$cs_montserrat_fonts; }
  .mat-form-field-appearance-outline .mat-select-arrow-wrapper { transform: translateY(5%); }
  .mat-input-element, .mat-form-field-label, .mat-select-placeholder, .mat-select-value-text { font-family: $cs_montserrat_fonts; font-size: 18px; font-weight: 600; }
  .mat-input-element { color: $themecolor1; }
  .mat-form-field-wrapper { padding-bottom: 40px; margin-bottom: 0px !important; }
  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end, .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap, .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start { border-width: 0px;  }
  .mat-select-arrow { color: rgba(0,0,0,.54) !important; }
  .login-btn { width: 100%; height: 50px; font-weight: 700; min-width: auto !important; }
  .logo-bottom {
    & { margin-top: 80px; text-align: center; }
    img { width: 200px; }
   }
}

// =================== login ===================

// =================== start ===================
.start-page {
  & { padding: 60px 0px; background-color: #f5efe0; }

  .start-top {
    & { text-align: center; }
    .small-text { color: #333333; font-family: $cs_montserrat_fonts; font-size: 18px; font-weight: 500; letter-spacing: 0.4px; line-height: normal; }
    .cs-text { color: #333333; font-family: "butler-bold"; font-size: 37px; font-weight: 400; letter-spacing: 0.6px; line-height: normal; }
    .client-name { color: #333333; font-family: $cs_montserrat_fonts; font-size: 30px; font-weight: 600; line-height: normal; }
  }

  .start-mid {
    & { display: flex; flex-wrap: wrap; margin: 60px auto; max-width: 1056px; }
    .prework-block {
      & { position: relative; cursor: pointer; flex: 0 0 350px; height: 170px; background-color: #202945; margin: 1px; }
      .block {
        & { display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; transition: all 0.6s ease-in-out; }
        img { width: 60px; margin-bottom: 15px; }
        h3 { font-family: 'Montserrat', Sans-serif; color: #fff; font-size: 18px; font-weight: 600; letter-spacing: 0.1px; margin-bottom: 0px; }
      }
      .block-hover {
        & { opacity: 0; visibility: hidden; position: absolute; top: 20px; width: calc(100% - 64px); height: calc(100% - 64px); background-color: $csyellow; padding: 32px; text-align: center; transition: all 0.6s ease-in-out; }
        h3 { font-family: $cs_montserrat_fonts; font-size: 18px; font-weight: 600; letter-spacing: 0.1px; line-height: normal; margin-bottom: 20px; }
        .hover-text { font-family: $cs_montserrat_fonts; font-size: 14px; font-weight: 300; line-height:1.5; color:$themecolor1; }
        .hover-text em { font-weight: 300; }
      }
      &:hover {
        .block-hover { opacity: 1; visibility: visible; top: 0px; }
        .block { opacity: 0; visibility: hidden; }
        .block-hover h3, .block-hover .hover-text {
          -webkit-animation: fadein 0.6s; /* Safari, Chrome and Opera > 12.1 */
          -moz-animation: fadein 0.6s; /* Firefox < 16 */
           -ms-animation: fadein 0.6s; /* Internet Explorer */
            -o-animation: fadein 0.6s; /* Opera < 12.1 */
               animation: fadein 0.6s;
        }
        .block h3, .block img {
          -webkit-animation: transformUp 0.6s; /* Safari, Chrome and Opera > 12.1 */
          -moz-animation: transformUp 0.6s; /* Firefox < 16 */
           -ms-animation: transformUp 0.6s; /* Internet Explorer */
            -o-animation: transformUp 0.6s; /* Opera < 12.1 */
               animation: transformUp 0.6s;
        }
      }
    }
  }

  .start-bottom {
    &{ display: flex; flex-wrap: wrap; align-items: center; justify-content: center; }
    span { color: #333333; font-family: $cs_montserrat_fonts; font-size: 13px; font-weight: 400; letter-spacing: 0.3px; margin-right: 10px; }
    a { line-height: 0; }
    a img { width: 125px; }
  }

  @keyframes fadein {
      from { opacity: 0; transform:translate3d(0,40px,0); }
      to   { opacity: 1; transform:none; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
      from { opacity: 0; transform:translate3d(0,40px,0);}
      to   { opacity: 1; transform:none; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
      from { opacity: 0; transform:translate3d(0,40px,0);}
      to   { opacity: 1; transform:none; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
      from { opacity: 0; transform:translate3d(0,40px,0);}
      to   { opacity: 1; transform:none; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
      from { opacity: 0; transform:translate3d(0,40px,0);}
      to   { opacity: 1; transform:none; }
  }

  @keyframes transformUp {
    from { transform:none; }
    to   { transform:translate3d(0,-40px,0); }
  }

  /* Firefox < 16 */
  @-moz-keyframes transformUp {
    from { transform:none; }
    to   { transform:translate3d(0,-40px,0); }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes transformUp {
    from { transform:none; }
    to   { transform:translate3d(0,-40px,0); }
  }

  /* Internet Explorer */
  @-ms-keyframes transformUp {
    from { transform:none; }
    to   { transform:translate3d(0,-40px,0); }
  }

  /* Opera < 12.1 */
  @-o-keyframes transformUp {
    from { transform:none; }
    to   { transform:translate3d(0,-40px,0); }
  }
}


// =================== start ===================
brand-messaging-container {
  .mat-horizontal-stepper {
    & { background-color: transparent; }
    .mat-horizontal-stepper-header-container { padding: 0px 300px; }
    .mat-horizontal-content-container { padding: 200px; margin-top: 300px; }
    .mat-horizontal-stepper-header { background-color: transparent; z-index: 9; padding: 0 0px; flex-direction: column; align-items: center; justify-content: center; text-align: center; }
    .mat-horizontal-stepper-header .mat-step-icon { margin-right: 0px; margin-bottom: 8px; background-color: $themecolor1; }
    .mat-step-icon { background-color: transparent; height: 30px; width: 30px; font-weight: 600; font-family: $cs_montserrat_fonts; font-size: 18px; }
    .mat-step-header .mat-step-icon-selected { background-color: $csyellow !important; color:$themecolor1; }
    .mat-step-icon .mat-icon { height: 30px; width: 30px; font-size: 30px; background-color: #fafafa; }
    .mat-step-text-label { font-weight: 500; font-family: $cs_montserrat_fonts; font-size: 15px; }
    .mat-step-label-selected .mat-step-text-label { font-weight: 700; }
    .mat-stepper-horizontal-line { border-top-width: 2px; border-top-color:$themecolor1; margin: -28px -16px 0; }
  }
}

